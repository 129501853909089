<template>
  <div class="sidebar">
    <div class="dashboard">
      <h1>Levenspad</h1>
      <p>Hieronder staan de verschillende fases van het levenspad. Deze kunnen ook in de balk bovenaan de pagina worden geselecteerd bij het bijbehorende icoontje.</p>
      <br>
          <li><router-link class="item" to="/Niet-Pluis/0">Niet Pluis</router-link></li>
          <li><router-link class="item" to="/Diagnose/0">Diagnose</router-link></li>
          <li><router-link class="item" to="/Leven-met-Dementie/0">Leven met Dementie</router-link></li>
          <li><router-link class="item" to="/Nazorg/0">Stervensfase en Nazorg</router-link></li>
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: { },
  methods: {
  }
}

</script>

<style>
.item {
  color: var(--color-1);
}
</style>
